define("@huvepharma/huvepharma-aviapp-components/templates/components/header/page-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GukMjdJk",
    "block": "[[[10,\"header\"],[14,0,\"page-action-header\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"subtitle\"],[12],[1,[34,0]],[13],[1,\"\\n    \"],[10,0],[14,0,\"actions\"],[12],[18,1,null],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"headerTitle\",\"yield\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/header/page-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});