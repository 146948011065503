define("@huvepharma/huvepharma-aviapp-components/templates/components/charts/legend", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "g4c7vN0F",
    "block": "[[[10,\"g\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"items\"]]],null]],null],null,[[[41,[28,[37,3],[[30,1,[\"shape\"]],\"square\"],null],[[[1,\"      \"],[10,\"rect\"],[15,\"height\",[29,[[30,1,[\"height\"]]]]],[15,\"width\",[29,[[30,1,[\"width\"]]]]],[14,\"y\",\"0\"],[15,\"x\",[29,[[30,1,[\"x\"]]]]],[15,\"fill\",[29,[[30,1,[\"fill\"]]]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,3],[[30,1,[\"shape\"]],\"circle\"],null],[[[1,\"      \"],[10,\"ellipse\"],[15,\"ry\",[29,[[30,1,[\"height\"]]]]],[15,\"rx\",[29,[[30,1,[\"width\"]]]]],[15,\"cy\",[29,[[30,1,[\"y\"]]]]],[15,\"cx\",[29,[[30,1,[\"x\"]]]]],[14,\"stroke-opacity\",\"null\"],[14,\"stroke-width\",\"0\"],[14,\"stroke\",\"#000\"],[15,\"fill\",[29,[[30,1,[\"fill\"]]]]],[12],[13],[1,\"\\n\"]],[]],null]],[1]],null],[13],[1,\"\\n\"],[10,\"text\"],[15,\"x\",[29,[[30,0,[\"textProps\",\"x\"]]]]],[15,\"y\",[29,[[30,0,[\"textProps\",\"y\"]]]]],[14,\"font-family\",\"Muli, Arial, Helvetica, sans-serif\"],[14,\"font-size\",\"16\"],[14,\"stroke-opacity\",\"null\"],[14,\"stroke-width\",\"0\"],[14,\"stroke\",\"#000\"],[14,\"fill\",\"#a2afb4\"],[12],[1,\"\\n  \"],[10,\"tspan\"],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"item\",\"@label\"],false,[\"each\",\"-track-array\",\"if\",\"eq\"]]",
    "moduleName": "@huvepharma/huvepharma-aviapp-components/templates/components/charts/legend.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});