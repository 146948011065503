define("@huvepharma/huvepharma-aviapp-components/helpers/conversion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fahrenheitToCelsius = _exports.celsiusToFahrenheit = _exports.highChartsDateTimeFormat = _exports.convertToMetric = _exports.convertToImperial = _exports.getDecimalPlaces = _exports.getUnitLabel = _exports.fieldsToConvert = void 0;
  var GRAM_TO_POUND = 0.0022046;
  var POUND_TO_GRAM = 453.597024404;
  var KG_TO_POUND = 2.2046224;
  var POUND_TO_KG = 0.4535924;
  var LITER_TO_GALLON = 0.264172052;
  var GALLON_TO_LITER = 3.7854117840007;
  var ML_TO_GALLON = 0.000264172;
  var GALLON_TO_ML = 3785.41;
  var SQUARE_METERS_TO_SQUARE_FEET = 10.7639104;
  var SQUARE_FEET_TO_SQUARE_METERS = 0.09290304;
  var GRAM_TO_POUND_FOR_12_EGGS = 0.0264552;
  var POUND_FOR_12_EGGS_TO_GRAM = 37.7997520337;
  var fieldsToConvert = {
    feedintake: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_feedintake_unitlb',
        metric: 'modules_flock_fields_feedintake_unit'
      }
    },
    feedintakeguideline: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_feedintake_unitlb',
        metric: 'modules_flock_fields_feedintake_unit'
      }
    },
    feedintakedaily: {
      imperial: {
        weight_flock: KG_TO_POUND,
        feedintake_unit: GRAM_TO_POUND,
        default: KG_TO_POUND
      },
      metric: {
        weight_flock: POUND_TO_KG,
        feedintake_unit: POUND_TO_GRAM,
        default: POUND_TO_KG
      },
      labels: {
        imperial: 'modules_flock_fields_feedintake_unitlb',
        metric: 'modules_flock_fields_feedintake_unit'
      },
      hasMoreThanOneUnit: true
    },
    waterintake: {
      imperial: ML_TO_GALLON,
      metric: GALLON_TO_ML,
      labels: {
        imperial: 'modules_flock_fields_waterintake_unitlb',
        metric: 'modules_flock_fields_waterintake_unit'
      }
    },
    waterintakedaily: {
      imperial: {
        amount_flock: LITER_TO_GALLON,
        waterintake_unit: ML_TO_GALLON,
        default: LITER_TO_GALLON
      },
      metric: {
        amount_flock: GALLON_TO_LITER,
        waterintake_unit: GALLON_TO_ML,
        default: GALLON_TO_LITER
      },
      labels: {
        imperial: 'modules_flock_fields_waterIntake_unitLb',
        metric: 'modules_flock_fields_waterintake_unit'
      },
      hasMoreThanOneUnit: true
    },
    bodyweight: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_bodyweight_unitlb',
        metric: 'modules_flock_fields_bodyweight_unit'
      }
    },
    bursaweight: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_bodyweight_unitlb',
        metric: 'modules_flock_fields_bodyweight_unit'
      }
    },
    spleenweight: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_bodyweight_unitlb',
        metric: 'modules_flock_fields_bodyweight_unit'
      }
    },
    gizzardweight: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_bodyweight_unitlb',
        metric: 'modules_flock_fields_bodyweight_unit'
      }
    },
    liverweight: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_bodyweight_unitlb',
        metric: 'modules_flock_fields_bodyweight_unit'
      }
    },
    rightventricleweight: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_bodyweight_unitlb',
        metric: 'modules_flock_fields_bodyweight_unit'
      }
    },
    totalventricleweight: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_bodyweight_unitlb',
        metric: 'modules_flock_fields_bodyweight_unit'
      }
    },
    bodyweightguideline: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_bodyweight_unitlb',
        metric: 'modules_flock_fields_bodyweight_unit'
      }
    },
    adg: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'modules_flock_fields_adgavg_unitlb',
        metric: 'modules_flock_fields_adgavg_unit'
      }
    },
    climate: {
      imperial: function imperial(x) {
        return celsiusToFahrenheit(x);
      },
      metric: function metric(x) {
        return fahrenheitToCelsius(x);
      },
      isFunction: true,
      labels: {
        imperial: 'flock_report_header_imperial_temperature',
        metric: 'flock_report_header_metric_temperature'
      }
    },
    slaughterweight: {
      imperial: GRAM_TO_POUND,
      metric: POUND_TO_GRAM,
      labels: {
        imperial: 'scoring_gfr_header_imperial_weight_bird',
        metric: 'scoring_gfr_header_metric_weight_bird'
      }
    },
    farm_house_area: {
      imperial: SQUARE_METERS_TO_SQUARE_FEET,
      metric: SQUARE_FEET_TO_SQUARE_METERS,
      labels: {
        imperial: 'label_unit_square_feet',
        metric: 'label_unit_square_meters'
      }
    },
    eggmasshenhoused: {
      imperial: KG_TO_POUND,
      metric: POUND_TO_KG,
      labels: {
        imperial: 'label_lbs',
        metric: 'label_kg'
      }
    },
    eggweight: {
      imperial: GRAM_TO_POUND_FOR_12_EGGS,
      metric: POUND_FOR_12_EGGS_TO_GRAM,
      labels: {
        imperial: 'egg_production_eggweight_imperial_unit',
        metric: 'flock_report_header_metric_weight'
      }
    }
  };
  /**
   * Get the unit label for a module based on the measurementSystem
   * @param {String} moduleId the module id
   * @param {String} measurementSystem The measurementSystem
   * @returns {String} The label or null if not found
   */

  _exports.fieldsToConvert = fieldsToConvert;

  var getUnitLabel = function getUnitLabel(moduleId, measurementSystem) {
    var id = moduleId.toLowerCase().replace('avg', '');

    if (id in fieldsToConvert && (measurementSystem === 'imperial' || measurementSystem === 'metric')) {
      return fieldsToConvert[id].labels[measurementSystem];
    }

    return null;
  };
  /**
   * Get the decimal places of a module
   * @param {String} moduleId
   * @param {String} measurementSystem
   */


  _exports.getUnitLabel = getUnitLabel;

  var getDecimalPlaces = function getDecimalPlaces(moduleId, measurementSystem) {
    if (!moduleId || !measurementSystem) {
      throw Error('Imcomplete parameters to determine number of decimals');
    }

    var id = moduleId.toLowerCase().replace('avg', '');

    if (id.indexOf('mortality') !== -1) {
      return 1;
    }

    if (id in fieldsToConvert) {
      return measurementSystem === 'imperial' ? 3 : 0;
    }

    return 2;
  };
  /**
   *
   * @param value
   * @param fieldId
   * @param decimals
   * @param fieldUnit
   * @returns {*}
   */


  _exports.getDecimalPlaces = getDecimalPlaces;

  var convertToImperial = function convertToImperial(value, fieldId) {
    var decimals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
    var fieldUnit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    // Only convert fields that are in fieldsToConvert
    var options = fieldsToConvert[typeof fieldId === 'string' ? fieldId.toLowerCase().replace('avg', '') : null];

    if (options) {
      var convertedValue;

      if (options.isFunction) {
        convertedValue = options.imperial(value);
      } else {
        var ratio = options.imperial;

        if (options.hasMoreThanOneUnit) {
          ratio = fieldUnit in ratio ? ratio[fieldUnit] : ratio['default'];
        }

        convertedValue = parseFloat(value) * ratio;
      }

      if (decimals) {
        return !isNaN(convertedValue) ? parseFloat(convertedValue.toFixed(decimals)) : value;
      }

      return !isNaN(convertedValue) ? convertedValue : value;
    }

    return value;
  };
  /**
   * @param value
   * @param fieldId
   * @param decimals
   * @param fieldUnit
   * @returns {*}
   */


  _exports.convertToImperial = convertToImperial;

  var convertToMetric = function convertToMetric(value, fieldId) {
    var decimals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var fieldUnit = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    // Only convert fields that are in fieldsToConvert
    var options = fieldsToConvert[typeof fieldId === 'string' ? fieldId.toLowerCase().replace('avg', '') : null];

    if (options) {
      var convertedValue;

      if (options.isFunction) {
        convertedValue = options.metric(value);
      } else {
        var ratio = options.metric;

        if (options.hasMoreThanOneUnit) {
          ratio = fieldUnit in ratio ? ratio[fieldUnit] : ratio['default'];
        }

        convertedValue = parseFloat(value) * ratio;
      }

      if (decimals) {
        return !isNaN(convertedValue) ? parseFloat(convertedValue.toFixed(decimals)) : value;
      }

      return !isNaN(convertedValue) ? convertedValue : value;
    }

    return value;
  };
  /**
   *
   * @param value
   * @returns {*}
   */


  _exports.convertToMetric = convertToMetric;

  var highChartsDateTimeFormat = function highChartsDateTimeFormat(value) {
    // '%Y-%m-%d' for highcharts
    // 'DD-MM-YYYY' for default
    var customGraphFormat = value.replace('YYYY', '%Y').replace('MM', '%m').replace('DD', '%d');
    return customGraphFormat;
  };

  _exports.highChartsDateTimeFormat = highChartsDateTimeFormat;

  var celsiusToFahrenheit = function celsiusToFahrenheit(value) {
    return value * 9 / 5 + 32;
  };

  _exports.celsiusToFahrenheit = celsiusToFahrenheit;

  var fahrenheitToCelsius = function fahrenheitToCelsius(value) {
    return (value - 32) * 5 / 9;
  };

  _exports.fahrenheitToCelsius = fahrenheitToCelsius;
});